import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import '../../helpers/fontawesome';
import { IGuideline, ISection } from "../../models/cmsModels";
import Sidebar from "../shared/Sidebar/Sidebar";
import Sections from "../shared/Sections/Sections";
import SEO from "../seo";
import { getIsUserAuthenticated } from "../../services/auth";

import './guideline.scss';
import { StaticImage } from "gatsby-plugin-image";

interface IProps {
  link: string,
  setLink: any,
  guidelines: any
}

const Guideline = (props: IProps) => {
  const [guidelines, setData] = useState(undefined as IGuideline[]);
  const [isHeadingExpanded, setHeadingExpanded] = useState([]);
  const [sidebarHeight, setSidebarHeight] = useState(300);
  const [isRendered, setRendered] = useState(false);
  const isAuthenticated = getIsUserAuthenticated();

  const childRef = useRef();

  let guideline: IGuideline = {} as IGuideline;

  useEffect(() => {
    const fn = async () => {
      if (!guidelines) {
        let list: IGuideline[] = props.guidelines;
        setData(list);

        const headingExpanded = Array.from({length: list.length}, () => false);
        setSidebarHeight((window as any).innerHeight / 1.33);

        setHeadingExpanded(headingExpanded);
      }
    };

    if (!isRendered) {
      const timeout = setTimeout(() => {
        const id = location.hash.substr(1);
        const element = document.getElementById(id);
    
        if (element) {
          element.scrollIntoView();
          setRendered(true);
        }
      }, 1000);

      fn();
    }
  });

  const serialAlpha = (n: number) => {
    return String.fromCharCode(n + 97);
  };

  const onGuidelineClicked = (guideline: IGuideline) => {
    props.setLink(guideline.link);
    collapseSideMenu();
  };

  const goToSection = (gl: IGuideline, sec: ISection) => {
    if (gl.link != props.link) {
      props.setLink(gl.link);
    }
    collapseSideMenu();
    location.hash = sec.link;
  };

  const toggle = (i: number) => {
    const headingExpanded = Array.from({length: isHeadingExpanded.length}, () => false);
    headingExpanded[i] = !isHeadingExpanded[i];
    setHeadingExpanded(headingExpanded);
  };

  const TOC = (
      !props.guidelines ? <></> :
      <div className="gl-toc">
      {
          props.guidelines.map((gl: IGuideline, g: number) => (
            <div key={"guideline_" + g}>
              <div className="gl-toc-guideline" onClick={() => toggle(g)}>
                <span onClick={() => onGuidelineClicked(gl)}>{g + 1}. {gl.title}</span>
                <FontAwesomeIcon icon={isHeadingExpanded[g] ? 'caret-up' : 'caret-down'} className="fa-lg" />
              </div>
              <div className={'gl-toc-sections' + (isHeadingExpanded[g] ? ' gl-toc-sections-expanded' : '')}>
              {
                gl.sections.map((section: ISection, s: number) => (
                  <div className="gl-toc-section" key={"section_" + s} onClick={() => goToSection(gl, section)}>
                    <div className="gl-toc-alpha">{serialAlpha(s)}. </div><div>{section.title}</div>
                  </div>
                ))
              }
              </div>
            </div>
          ))
      }
      </div>
  );

  if (guidelines && guidelines.length) {
    if (props.link) {
      guideline = guidelines.find((gl: IGuideline) => gl.link === props.link);
      if (!guideline) guideline = guidelines[0];
    }
    else {
      guideline = guidelines[0];
    }
  }

  const collapseSideMenu = () => {
    (childRef.current as any).collapseSideMenu();
  };

  return (
      <div className="guideline">
        <SEO title={guideline.title} description="" />
        <Sidebar margin='50px' width="260px" height={sidebarHeight} isExpanded={false} ref={childRef} >
          <div>{TOC}</div>
        </Sidebar>
        <div className="gl-contents-panel" onClick={() => collapseSideMenu()}>
          <h2>{guideline.title}</h2> 
          {
            !guideline.pdfFile ? <></> :
            !isAuthenticated ?  
            <div style={{textAlign: 'right'}}>
              <a target="_blank" title="View/Download pdf">
                <StaticImage className="rs-pdf-grey" src="../../assets/images/download-pdf.png" alt="" />
                <br></br>You Need To<br></br>Be Signed In
              </a>
            </div> : 
            <div style={{textAlign: 'right'}}>
              <a href={guideline.pdfFile.publicURL} target="_blank" className="download-link" title="View/Download pdf">
                <StaticImage className="rs-pdf" src="../../assets/images/download-pdf.png" alt="" />
              </a>
            </div> 
          }

          {
            !isAuthenticated ? <div dangerouslySetInnerHTML={{__html: guideline.blurb}}></div> :
            <>
              <div dangerouslySetInnerHTML={{__html: guideline.contents}}></div>
              {
                !guideline.sections ? <></> :
                <Sections sections={guideline.sections} />
              }
            </>
          }
      </div>
    </div>
  );
};

export default Guideline;