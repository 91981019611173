import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { EScreenParams } from '../../../constants/enums';
import { IImage, IParagraph, ISection } from '../../../models/cmsModels';
import ImageGallery from '../ImageGallery/image-gallery';

import './Sections.scss';

interface IProps {
    sections: ISection[];
}

const Sections: any = (props: IProps) => {
    const [isImageGalleryOpen, setImageGalleryOpen] = useState(false);
    const [galleryImages, setGalleryImages] = useState([] as IImage[]);
    const [imageIndex, setImageIndex] = useState(null as number);

    const openImageGallery = (images: IImage[], index: number) => {
        setGalleryImages(images);
        setImageIndex(index);
        setImageGalleryOpen(true);
    };
    
      const closeImageGallery = () => {
        setGalleryImages([]);
        setImageIndex(null);
        setImageGalleryOpen(false);
    };
        
    return (
        <div className="sections">
            {
                !isImageGalleryOpen ? <></> :
                <ImageGallery images={galleryImages} defaultIndex={imageIndex} onClose={() => closeImageGallery()} />
            }
            {
                props.sections.map((section: ISection, s: number) => (
                    <div id={section.link} key={"section_" + s} className="sec-section">
                    <h3>{section.title}</h3>
                    {
                    !section.paragraphs ? <></> :
                    section.paragraphs.map((para: IParagraph, p: number) => (
                        <div key={"sec-para_" + p} className="sec-paragraph" style={paragraphStyle(para)}>
                        <div className='sec-contents' dangerouslySetInnerHTML={{__html: para.contents}}></div>
                        <Row className="sec-images" style={imagesStyle(para)}>
                        {
                            !para.images ? <></> :
                            para.images.map((image: IImage, i: number) => {
                            const imageSrc = image?.url;
                                                // image?.formats?.medium?.childImageSharp?.fluid?.base64 ||
                                                // image?.formats?.large?.childImageSharp?.fluid?.base64 ||
                                                // image?.formats?.small?.childImageSharp?.fluid?.base64 ||
                                                // image?.formats?.thumbnail?.childImageSharp?.fluid?.base64;
                            
                            return (
                                <Col key={"sec-image_" + i}>
                                <img src={imageSrc} onClick={() => openImageGallery(para.images, i)} title="Click to enlarge"/>
                                </Col>
                            )
                            })
                        }
                        </Row>
                        </div>
                    ))
                    }
                </div>
                ))
            }
        </div>
    )
};

const paragraphStyle: any = (paragraph: IParagraph) => {
    let direction = '';

    if (window.innerWidth <= EScreenParams.minWidthForMobile) {
      direction = 'column';
    }
    else {
      switch (paragraph.imagesOrientation) {
        case 'Left': direction = 'row-reverse'; break;
        case 'Right': direction = 'row'; break;
        case 'Top': direction = 'column-reverse'; break;
        case 'Bottom': direction = 'column'; break;
        default: direction = 'column'; break;
      }
    }

    return {
      display: 'flex',
      flexDirection: direction
    }
}

const imagesStyle: any = (paragraph: IParagraph) => {
    let direction = '';

    switch (paragraph.imagesOrientation) {
      case 'Left':
      case 'Right': direction = 'column'; break;
      case 'Top': 
      case 'Bottom': direction = 'row'; break;
      default: direction = 'row'; break;
    }

    return {
      display: 'flex',
      flexDirection: direction
    }
};

export default Sections;