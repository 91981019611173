import React from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { Card, Container, FormControl, Row } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IGuideline } from "../models/cmsModels";
import Layout from "../components/Layout";
import Guideline from "../components/Guideline/guideline";
// import { loginRequest } from "../msal/authConfig";
import '../helpers/fontawesome';
import "../assets/css/main.scss";
import SEO from "../components/seo";

import nswLogo from "../assets/images/nsw-env-trust-logo.jpg";

const Guidelines = ({data}) => {
  
  // compare function to sort the guidelines
  const compare = ( a: IGuideline, b: IGuideline ) => {
    if ( a.sortOrder < b.sortOrder ){
      return -1;
    }
    if ( a.sortOrder > b.sortOrder ){
      return 1;
    }
    return 0;
  }

  const guidelines = data.guidelines.nodes.sort(compare);

  const { guidelinesTitle, guidelinesDescription } = data?.seo?.nodes[0];

  const [link, setLink] = useQueryParam("link", StringParam);

  let searchTerm: any;

  const getUrl = () => "/search-guidelines" + (searchTerm && searchTerm.value ? ("?term=" + searchTerm.value) : "");

  const onKeyPress = (target: any) => {
    if (target.charCode === 13) {
      navigate();
    }
  };

  const navigate = () => {
    location.href = getUrl();
  };

  // const authRequest = {
  //   ...loginRequest
  // };

  return (
    <Layout>
      <SEO title={guidelinesTitle} description={guidelinesDescription}></SEO>
      {
        link
        ? <Guideline link={link} setLink={(link: string) => setLink(link)} guidelines={guidelines} />
        :
          <Container className="guidelines fl-page">
            <Row>
              <p className="gl-para">
              <img width="10%" src={nswLogo} alt="nsw-env-logo" />&nbsp;This part of the project has been assisted by the New South Wales Government through its Environmental Trust.
              </p>
            </Row>
            <Row>              
              <div className="gl-heading row">
                <span className="col-sm-7 col-md-8"><h3>Florabank Guidelines</h3></span>
                <div className="gl-searchbox col-sm-5 col-md-4">
                  <FormControl type="text" placeholder="Search Guidelines" ref={(ref: any) => searchTerm = ref} 
                              onKeyPress={onKeyPress}/>
                  <div className="button" onClick={navigate}>
                    <FontAwesomeIcon icon='search' className="fa-2x" />
                  </div>
                </div>
              </div>
            </Row>            
            <Row>
                <p className="gl-para">
                  The Florabank Guidelines consist of 15 modules that roughly follow the seed supply chain. 
                  Log in to read the complete guidelines.
                  &nbsp;<Link to="/search-guidelines">Click&nbsp;the&nbsp;search&nbsp;icon &nbsp;<FontAwesomeIcon icon='search' />&nbsp;&nbsp;to&nbsp;search&nbsp;</Link>
                  &nbsp;for occurances 
                  of a word or phrase in the guidelines.
                </p>
            </Row>
            <Row className="gl-cards">
              {
                !guidelines ? <></> :
                guidelines.map((guideline: IGuideline, g: number) => {
                  const { title, image } = guideline;
                  
                  return (
                    <Card key={'guidelines_' + g} onClick={() => setLink(guideline.link)}>
                      <Card.Body>
                        <Card.Title>
                          <div>
                            { !image ? <></> : <img className="gl-img" alt={title} src={image.childImageSharp.fluid.src} /> }
                          </div>
                        </Card.Title>
                        <Card.Text>
                          <div className="gl-title" dangerouslySetInnerHTML={{__html: title}}></div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  );
                })
              }
            </Row>
          </Container>
      }
    </Layout>
  );
};

export const query = graphql`
  query Guidelines {
    guidelines: allStrapiGuideline {
      nodes {
        id
        blurb
        title
        link
        sortOrder
        contents
        sections {
          title
          link
          paragraphs {
            contents
            imagesOrientation
            images {
              url
            }
          }
        }
        image {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        pdfFile {
          publicURL
        }
      }
    },
    seo: allStrapiSeo {
      nodes {
        guidelinesDescription
        guidelinesTitle
      }
    },
  }
`;

export default Guidelines;