import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import '../../../helpers/fontawesome';
import './Sidebar.scss';

interface ISidebarProps {
    margin: string;
    isExpanded: boolean;
    transDelay: boolean;
    width: string;
    height: string;
    color: string;
    backgroundColor: SVGStringList;
    children: any;
}

const Sidebar : React.FC<any> = forwardRef((props: ISidebarProps, ref: any) => {
    useImperativeHandle(ref, () => ({
        collapseSideMenu() {
            setSideMenuExpanded(false);
        }
    }));

    const width = props.width || '200px';

    const [isExpanded, setSideMenuExpanded] = useState(props.isExpanded);

    const history = useHistory();

    const onSelect = (selected: any) => {
        let path = '';
        switch (selected) {
            case 'home': path=`/home`; break;
            default: path = '/' + selected;
        }

        setSideMenuExpanded(false);

        history.push(path);
    };

    const onToggle = () => {
        setSideMenuExpanded(!isExpanded);
    };

    const delay = `${props.transDelay || 0.5}s`;

    const sidebarStyle = {
        height: props.height,
        color: props.color || '#fff',
    }

    const contentsStyle: any = {
        transition: `width ${delay}, opacity ${delay}, visibility ${delay}`,
        width: isExpanded ? width : 0,
        opacity: isExpanded ? 1 : 0,
        visibility: isExpanded ? 'visible' : 'hidden',
        color: props.color || 'white',
        backgroundColor: props.backgroundColor || ''
    }

    const headingStyle: any = {
        marginTop: props.margin,
        backgroundColor: props.backgroundColor || ''
    };

    const staticStyle: any = {
        width: props.width
    };

    return (
        <div className="app">
            <div className="sidebar" style={sidebarStyle}>
                <div className="contents" style={contentsStyle}>
                    <div className="static" style={staticStyle}>
                        {props.children}
                    </div>
                </div>
                <div className="heading" onClick={onToggle} style={headingStyle}>
                    <span>Contents</span>
                    <FontAwesomeIcon icon={isExpanded ? 'arrow-circle-right' : 'arrow-circle-left'} />
                </div>
            </div>
        </div>
    );
});

export default Sidebar;
